import '@css/main.scss';
import {Sliders} from './components/Sliders';
import {Header} from './components/Header';
import {Filter} from './components/Filter';
import {Menu} from './components/Menu';
import {Lightbox} from './components/Lightbox';
import AOS from 'aos/dist/aos.js'


window.onload = () => {
    new Sliders()
    new Header()
    new Filter()
    new Menu()
    new Lightbox()

    AOS.init();
}

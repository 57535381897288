import $ from 'jquery';

export class Filter {
    constructor() {
        this.container = document.querySelector('*[data-archive]')
        if (!this.container) {
            return;
        }
        this.type = this.container.getAttribute('data-archive')

        this.moreBtn = this.container.querySelector('*[data-archive-btn-more]');
        this.moreBtnInitText = this.moreBtn.innerHTML
        this.filters_container = this.container.querySelector('*[data-filters]');
        this.form = this.container.querySelector('form[data-filter-form]');
        this.filters_type = this.filters_container.getAttribute('data-filters');
        this.filterreset = this.filters_container.querySelectorAll('*[data-filter="*"]');
        this.filters = this.filters_container.querySelectorAll('*[data-filter]');
        this.itemsContainer = this.container.querySelector('*[data-archive-endpoint]')
        this.init()
    }

    init() {
        this.config = this.defaults()
        this.url = `${window.location.protocol}//${window.location.hostname}/wp-json/wp/v2/${this.type}`
        this.register()
    }

    defaults() {
        return {
            itemsperpage: parseInt(this.container.dataset.itemsperpage),
            itemsoffset: parseInt(this.container.dataset.itemsperpage),
            currentpage: 1,
            noMoreItems: false,
            currentTax: '',
        }
    }

    register() {
        if (this.form) {
            /** Event sur le form */
            this.form.addEventListener('change', evt => {
                evt.preventDefault();
                const target = evt.target;

                if (target.hasAttribute('data-filter')) {
                    this.filterreset.forEach(f => f.classList.remove('active'))
                }

                console.log('load')
                document.querySelector('.loader-custom').style.display = 'flex'

                this.config.currentpage = 1;
                this.itemsContainer.innerHTML = "";
                this.fetchFilter(true);
            })
        }

        this.filterreset.forEach(f => {
            f.addEventListener('click', (e) => {
                e.preventDefault();
                const id = f.dataset.filter
                this.reset()
                this.filters.forEach(flt => flt.checked = false)
                this.form.dispatchEvent(new CustomEvent('change'))

                f.classList.add('active')
            })
        })

        this.moreBtn.addEventListener('click', (e) => {
            e.preventDefault();
            this.config.currentpage += 1;
            if (this.config.noMoreItems) {
                this.moreBtn.setAttribute('hidden', '')
                return;
            }
            document.querySelector('.loader-button-custom .loader-custom').style.display = 'block'


            this.fetchFilter();
        })

    }

    fetchFilter(reset = false) {
        const params = new URLSearchParams(new FormData(this.form));
        params.append('per_page', this.config.itemsperpage)
        params.append('page', this.config.currentpage)

        fetch(`${this.url}?${params.toString()}&orderby=date&order=desc`)
            .then((r) => r.json().then(r => {
                if (reset) {
                    this.itemsContainer.innerHTML = "";
                }

                if (r.length === 0 || r.length < this.config.itemsperpage) {
                    console.log(r.length, this.config.itemsperpage, r.length < this.config.itemsperpage)
                    this.config.noMoreItems = true;
                    this.moreBtn.setAttribute('hidden', '')
                } else {
                    this.config.noMoreItems = false
                    this.moreBtn.removeAttribute('hidden')
                }


                if (r.length === 0 && this.config.currentpage === 1) {
                    this.moreBtn.innerHTML = 'Aucune réalisation trouvée';
                    this.config.noMoreItems = true;
                    this.moreBtn.removeAttribute('hidden')
                }

                this.appendItems(r);
                document.querySelector('.loader-custom').style.display = 'none'
                document.querySelector('.loader-button-custom .loader-custom').style.display = 'none'


            })
                .catch(r => {
                    this.moreBtn.setAttribute('hidden', '')
                    this.moreBtn.innerHTML = 'Aucun résulat trouvés 2';
                    this.config.noMoreItems = true;
                    console.log(r)
                }))
    }

    appendItems(items) {
        const template = document.querySelector('#archive-el');

        items.forEach((item) => {
            let clone = template.content.cloneNode(true);

            this.itemEvent(clone, item)
        })
    }

    itemEvent(clone, item) {
        const link = clone.querySelector('.post--link');
        const thumb = clone.querySelector('.post-image');
        const title = clone.querySelector('.post-body_title');
        const cat = clone.querySelector('.post-body_cat');

        // link.setAttribute('href', item.link)

        const thumbnail = document.createElement('img')
        if (item?.featured_image_url) {
            thumbnail.setAttribute('src', item?.featured_image_url)
            thumb.appendChild(thumbnail)
        }
        if (title) {
            title.innerHTML = item.title?.rendered
        }
        if (link) {
            link.href = item.link
        }
        if (cat) {

            cat.querySelector('.content').innerHTML = item?.term_first
            cat.classList.add('current')
        }

        this.itemsContainer.appendChild(clone)
    }
}

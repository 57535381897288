import Swiper, {Navigation, Pagination} from "swiper";

export class Sliders {
    constructor() {
        const sliders = document.querySelectorAll('.custom_swiper')

        if (sliders.length > 0) {
            sliders.forEach(function (el) {
                const sliderClass = "." + el.getAttribute('data-swiper')
                let args = null;
                if (el.getAttribute('data-swiper') === "custom_swiper") {
                    args = {
                        modules: [Pagination, Navigation],
                        slidesPerView: 1,
                        slidesPerGroup: 1,
                        spaceBetween: 36,
                        pagination: {
                            el: ".swiper-pagination",
                            clickable: true
                        },
                        navigation: {
                            prevEl: ".swiper-button-prev",
                            nextEl: ".swiper-button-next"
                        },
                        breakpoints: {
                            1200: {
                                slidesPerView: 3,
                                slidesPerGroup: 3,
                            },
                            800: {
                                slidesPerView: 2,
                                slidesPerGroup: 2,
                            }
                        }
                    }
                } else {
                    args = {
                        modules: [Pagination, Navigation],
                        pagination: {
                            el: ".swiper-pagination",
                            clickable: true
                        },
                        navigation: {
                            prevEl: ".swiper-button-prev",
                            nextEl: ".swiper-button-next"
                        }
                    }
                }

                new Swiper(sliderClass, args)
            })
        }
    }
}
